import { getMethod, gifyGetApi, stickerGetApi } from '../Utils/ApiServices';
import { setCookies } from '../Utils/Cookies';
const userDetails = async () => {
    const returnFlag = await getMethod('user/profile');
    if (returnFlag?.success) {
        setCookies('_adm_details', JSON.stringify({ name: returnFlag?.data?.firstName, id: returnFlag?.data?._id }))
    }
    return returnFlag;
}
const getCategoryList = async (endPoint) => {
    const returnFlag = await getMethod(endPoint);
    return returnFlag;
}
const getProductList = async (endPoint) => {
    const returnFlag = await getMethod(endPoint);
    return returnFlag;
}

// const getSubscribersList = async(page,limit,isAll)=>{
//     const returnFlag = await getMethod("newsletter/subscribers?page="+page+'&limit='+'limit'+'&isAll='+isAll);
//     return returnFlag;
// }

const getSubscribersList = async (page, limit, isAll) => {
    const returnFlag = await getMethod('newsletter/subscribers?page=' + page + '&limit=' + limit + '&isAll=' + isAll);
    return returnFlag;
}

const getSubscribersListSearch = async (page, limit, value) => {
    const returnFlag = await getMethod('newsletter/subscribers?page=' + page + '&limit=' + limit + '&search=' + value);
    return returnFlag;
}

const getTemplateList = async (endPoint) => {
    const returnFlag = await getMethod(endPoint);
    return returnFlag;
}
const getShowCaseList = async (endPoint) => {
    const returnFlag = await getMethod(endPoint);
    return returnFlag;
}
const getSortTemplateApi = async (value, page, slug, searchsub) => {
    const returnFlag = await getMethod('template/sort?page=' + page + '&limit=10&sort=' + value + '&slug=' + slug + '&private=' + searchsub);
    return returnFlag;
}
const getdashboardTop = async () => {
    const returnFlag = await getMethod('admin/sales/details');
    return returnFlag;
}
const getDemoCardDetails = async () => {
    const returnFlag = await getMethod('demo/canvasdetailfordemo?canvasId=608d295cf40f3c7082d22c32');
    return returnFlag;
}
const latestUserFilter = async (data) => {
    const returnFlag = await getMethod('/user/getlatestusers?startDate=' + data.startDate + '&endDate=' + data?.endDate);
    return returnFlag;
}
const getPricingPlanDetails = async (value) => {
    const returnFlag = await getMethod(value); //pricing/bundlelist
    return returnFlag;
}

const getCouponDetails = async (value) => {
    const returnFlag = await getMethod(value); //coupon/getcoupons
    return returnFlag;
}

const getUserlist = async (page, limit, isAll) => {
    const returnFlag = await getMethod('admin/userlist?page=' + page + '&limit=' + limit + '&isAll=' + (isAll ? false : false));
    return returnFlag;
}
const getUserlistteam = async (page, limit, isAll) => {
    const returnFlag = await getMethod('admin/getAllTeamPlan');
    return returnFlag;
}
const getUserlistSearch = async (page, limit, value, status, isSocial, data) => {
    const returnFlag = await getMethod('admin/userlist?page=' + page + '&limit=' + limit + '&search=' + value + '&isAll=' + false + '&isSocial=' + isSocial + (data.startDate ? '&startDate=' + data.startDate + '&endDate=' + data?.endDate : "") + (status ? ('&status=' + status) : ""));
    return returnFlag;
}

const getSubscriptionDetails = async (id) => {
    const returnPlan = await getMethod('plans');
    return returnPlan;
}

const getuserselectplan = async (id) => {
    if (id != undefined) {
        const returnPlan = await getMethod('user/plans' + "?ownerId=" + id);
        return returnPlan;
    }
}

const getteammembers = async (data) => {
    const returnFlag = await getMethod('/user/members/' + data.id + "?ownerId=" + data.ownerId);
    return returnFlag;
}

const getUserDetails = async (id) => {
    const returnFlag = await getMethod('admin/userDetail/' + id);
    return returnFlag;
}
const getSupportList = async (value) => {
    const returnFlag = await getMethod(value);
    return returnFlag;
}
const getUserOrderDetails = async (id, page, limit, type) => {
    const returnFlag = await getMethod('admin/userDetailCard/' + id + '?page=' + page + '&limit=' + limit + '&type=' + type);
    return returnFlag;
}

const cancel_subscriptionad = async (id) => {
    const returnFlag = await getMethod('/payment/cancelsubscription?_id=' + id);
    return returnFlag;
}

const getUserOrderSingleDetails = async (id) => {
    const returnFlag = await getMethod('component/admin/detailsforrecipient?canvasId=' + id);
    return returnFlag;
}
const getGifhy = async (data) => {
    const returnFlag = await gifyGetApi(data);
    return returnFlag.data;
}
const getSticker = async (data) => {
    const returnFlag = await stickerGetApi(data);
    return returnFlag.data;
}
const getToggleUserCouponAllow = async (id, couponId, status) => {
    const returnFlag = await getMethod('admin/coupon/' + id + '?coupon=' + status + '&couponId=' + couponId);
    return returnFlag;
}

const getInviteList = async (page, limit, isAll) => {
    const returnFlag = await getMethod('invite/alllist?page=' + page + '&limit=' + limit + '&isAll=' + isAll);
    return returnFlag;
}

const getInviteListSearch = async (page, limit, value, status, isopen, data) => {
    const returnFlag = await getMethod('invite/alllist?page=' + page + '&limit=' + limit + '&search=' + value + '&isOpen=' + isopen + (data.startDate ? '&startDate=' + data.startDate + '&endDate=' + data?.endDate : "") + (status ? ('&status=' + status) : ""));
    return returnFlag;
}

const getAllOrder = async (endpoint) => {
    const returnFlag = await getMethod(endpoint);
    return returnFlag;
}


const getAllOrderSort = async (page, limit, value) => {
    const returnFlag = await getMethod(value ? 'admin/order?status=' + value : 'admin/allOrder?page=' + page + '&limit=' + limit);
    return returnFlag;
}

const getOrderReceipts = async (id) => {
    const returnFlag = await getMethod('user/orderdetail?invoiceId=' + id);
    return returnFlag;
}

export { userDetails, getCategoryList, getAllOrderSort, latestUserFilter, getAllOrder, getInviteList, getInviteListSearch, getProductList, getTemplateList, getSticker, getSortTemplateApi, getUserlistteam, getUserlistSearch, getUserDetails, getSubscriptionDetails, getteammembers, getuserselectplan, getdashboardTop, getDemoCardDetails, getPricingPlanDetails, getCouponDetails, getUserlist, getSupportList, getUserOrderDetails, cancel_subscriptionad, getUserOrderSingleDetails, getShowCaseList, getGifhy, getToggleUserCouponAllow, getSubscribersList, getSubscribersListSearch, getOrderReceipts };