import React, { useEffect, useState, useRef, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { deletedTemplateApi } from '../Action/DeleteAction'
import { editTemplateApi } from '../Action/PutAction'
import {
  getTemplateList,
  getCategoryList,
  getProductList,
  getSortTemplateApi,
} from '../Action/GetAction'
import { addTemplateApi, searchTemplateApi } from '../Action/PostAction'
import {
  matrixify1,
  dateFormatConverter,
  multiSelectTemp,
  matrixify2,
} from '../Logic/Logic'
import { BASE_IMAGE_URL } from '../Utils/ApiServices'
import { useToasts } from 'react-toast-notifications'
import { toastComp } from '../Sections/Toast'
import { LoadingOverlay, Loader } from 'react-overlay-loader'
import { Modal } from 'react-responsive-modal'
import { format } from 'fecha'
import { FileUploader } from 'react-drag-drop-files'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { MultiSelect } from 'react-multi-select-component'
import { confirmAlert } from 'react-confirm-alert'
import { useDropzone } from 'react-dropzone'
import MultiSelect3 from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-overlay-loader/styles.css'
import 'react-responsive-modal/styles.css'
const TemplateList = (props) => {
  const { addToast } = useToasts()
  const [toggleSearchBar, setToggleSearchBar] = useState(true)
  const [productList, setProductList] = useState([])
  const [search, setSearch] = useState('')
  const [loader, setLoader] = useState(true)
  const [open, setOpen] = useState(false)
  const [editData, setEditData] = useState(false)
  const [editId, setEditId] = useState(false)
  const [name, setName] = useState('')
  const [front, setFront] = useState('')
  const [back, setBack] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [templateDescription, setTemplatedescription] = useState('')
  const [productType, setProductType] = useState('')
  const [category, setCategory] = useState([])
  const [keyWord, setKeyWord] = useState('')
  const [popularity, setPopularity] = useState(5)
  const [prevFront, setPrevFront] = useState('')
  const [prevBack, setPrevBack] = useState('')
  const formDataSheeet = new FormData()
  const [slug, setSlug] = useState('')
  const [categoryListSelect, setCategoryList] = useState([])
  const [selectFillter, setFilterSlug] = useState('')
  const [parentCategory, setParentCategory] = useState([])
  const [productoptList, setProductoptList] = useState([])
  const [selectSort, setselectSort] = useState('recent')
  const [frontAlt, setFrontAlt] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [templateTotal, setTemplateTotal] = useState(0)
  const [paginationOff, setPaginationOff] = useState(false)
  const useRefFront = useRef()
  const useRefBack = useRef()
  const fileTypesButton = ['JPG', 'PNG', 'GIF']
  const [mockupImages, setMockupImages] = useState([])
  const popularityArray = new Array(101).fill(0);
  const [searchsub, setSearchsub] = useState('');
  const onDrop = useCallback((acceptedFiles) => {
    setMockupImages(acceptedFiles)
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  function encodeImageFileAsURL(element) {
    return window.URL.createObjectURL(element)
  }
  const getListData = async (page, isNote, message) => {
    setPaginationOff(false)
    setProductList([]);
    await getTemplateList(
      'template/templateslist?page=' + page + '&limit=' + 10,
    ).then(async (response) => {
      if (response.success) {
        setLoader(false)
        onCloseModal()
        setProductList([response.template])
        setTemplateTotal(response.total)
        if (isNote) {
          toastComp(addToast, 'success', message)
        }
      }
    })
  }
  function setIndexCategory(value) {
    var listCartegory = []
    for (let li = 0; li < value.length; li++) {
      if (value[li]._id && value[li]?.name) {
        listCartegory.push({ value: value[li]._id, label: value[li]?.name })
      } else {
        listCartegory.push({ value: value[li] })
      }
    }
    return listCartegory
  }
  const onCloseModal = (props) => {
    setOpen(false)
    setEditData(false)
    setEditId(false)
    setName('')
    setPrevFront('')
    setPrevBack('')
    setFrontAlt('')
    setSlug('')
    setTemplatedescription('')
    setProductType('')
    setCategory([])
    setKeyWord('')
    setPopularity('')
    setMockupImages([])
    if (useRefFront.current) {
      useRefFront.current.value = ''
    }
    if (useRefBack?.current) {
      useRefBack.current.value = ''
    }
  }
  const handleSerach = async (value) => {
    setLoader(true)
    setProductList([]);
    if (value) {
      await searchTemplateApi(value).then(async (response) => {
        setLoader(false)
        if (response.success) {
          setProductList([response.data])
          setTemplateTotal(response.data.length)
          setPaginationOff(true)
        }
      })
    } else {
      setLoader(false)
      setPaginationOff(false)
      await handleSortOption(selectSort, pageNumber, selectFillter, searchsub)
    }
  }
  function getOnlyValue(arrayFetch) {
    var arrayList = []
    for (let li = 0; li < arrayFetch.length; li++) {
      if (arrayFetch[li]?.value) {
        arrayList.push(arrayFetch[li].value.toString())
      }
    }
    return arrayList
  }
  const handleEditProduct = async (id) => {
    setLoader(true)
    if (
      name != '' &&
      productType != '' &&
      category != '' &&
      keyWord != '' &&
      frontAlt != ''
    ) {
      const params = {
        templateId: id,
      }
      const formData = new FormData()
      if (front != '') {
        formData.append('front', front)
      }
      if (back != '') {
        formData.append('back', back)
      }
      if (slug) {
        formData.append('slug', slug)
      }
      formData.append('name', name)
      formData.append('category', JSON.stringify(getOnlyValue(category)))
      formData.append('productType', productType)
      formData.append('keyword', keyWord)
      formData.append('frontAlt', frontAlt)
      formData.append('description', templateDescription)
      formData.append('setPopularity', parseInt(popularity))
      formData.append('private', isPrivate)
      await editTemplateApi(formData, params).then(async (response) => {
        if (response.success) {
          await handleSortOption(selectSort, pageNumber, selectFillter, searchsub)
          setName('')
          setFront('')
          setBack('')
          setCategory([])
          setProductType([])
          setKeyWord('')
          setFrontAlt('')
          setTemplatedescription('')
          setSlug('')
          setPopularity('')
          setMockupImages([])
          setIsPrivate(false)
          if (useRefFront.current) {
            useRefFront.current.value = ''
          }
          if (useRefBack?.current) {
            useRefBack.current.value = ''
          }
          toastComp(addToast, 'success', 'Template edited Successfully!')
        } else {
          setLoader(false)
          toastComp(addToast, 'error', response.message)
        }
      })
    } else {
      setLoader(false)
      toastComp(addToast, 'error', 'Please fill the required field!')
    }
  }
  const handleAddProdcut = async () => {
    setLoader(true)
    // console.log("name",name);
    // console.log("front",front);
    // console.log("back",back);
    // console.log("mockupImages",mockupImages);
    // console.log("frontAlt",frontAlt);
    // console.log("productType",productType);
    // console.log("category",category);
    // console.log("keyWord",keyWord);
    // console.log("templateDescription",templateDescription);
    // console.log("Popularity",popularity);
    if (
      name != '' &&
      // back != '' &&
      mockupImages?.length &&
      frontAlt != '' &&
      productType != '' &&
      category != '' &&
      keyWord != '' &&
      templateDescription != '' &&
      popularity != ''
    ) {
      for (let li = 0; li < mockupImages?.length; li++) {
        const formData = new FormData()
        formData.append('name', name)
        if (mockupImages[li] != '') {
          formData.append('front', mockupImages[li])
        }
        if (back != '') {
          formData.append('back', back)
        }
        if (slug) {
          formData.append('slug', slug)
        }
        formData.append('category', JSON.stringify(getOnlyValue(category)))
        formData.append('productType', productType)
        formData.append('keyword', keyWord)
        formData.append('frontAlt', frontAlt)
        formData.append('description', templateDescription)
        formData.append('setPopularity', parseInt(popularity))
        formData.append('private', isPrivate)
        await addTemplateApi(formData).then(async (response) => {
          if (response.success) {
            if (li === mockupImages?.length - 1) {
              await handleSortOption(selectSort, pageNumber, selectFillter, searchsub)
              setName('')
              setFront('')
              setBack('')
              setCategory([])
              setProductType([])
              setSlug('')
              setKeyWord('')
              setTemplatedescription('')
              setFrontAlt('')
              setPopularity('')
              setMockupImages([])
              if (useRefFront.current) {
                useRefFront.current.value = ''
              }
              if (useRefBack?.current) {
                useRefBack.current.value = ''
              }
            }
            toastComp(addToast, 'success', 'Template Added Successfully!')
          } else {
            setLoader(false)
            toastComp(addToast, 'error', response.message)
          }
        })
      }
    } else {
      setLoader(false)
      toastComp(addToast, 'error', 'Please fill the required field!')
    }
  }
  const editCategory = (data) => {
    //console.log(data)
    setOpen(true)
    setEditData(true)
    setEditId(data?._id)
    setName(data?.name)
    setPrevFront(data?.front)
    setPrevBack(data?.back)
    setSlug(data?.slug)
    setIsPrivate(data?.private)
    setTemplatedescription(data?.description ? data?.description : '')
    setFrontAlt(data?.frontAlt ? data?.frontAlt : '')
    setProductType(data?.productType)
    if (setIndexCategory(data?.category)) {
      setCategory(setIndexCategory(data?.category))
    }
    setKeyWord(data?.keyword)
    setPopularity(data?.setPopularity)
  }
  const deleteCategory = async (id, index) => {
    setLoader(true)
    await deletedTemplateApi(id).then((response) => {
      if (response.success || response.status) {
        setLoader(false)
        productList[0]?.splice(index, 1)
        toastComp(addToast, 'success', 'Template deleted Successfully!')
      } else {
        setLoader(false)
        toastComp(addToast, 'error', 'Template id incorrect!')
      }
    })
  }
  const handleSortOption = async (value, page, slug, search) => {
    setselectSort(value)
    setFilterSlug(slug)
    setPageNumber(page)
    setProductList([]);
    setLoader(true)
    setPaginationOff(false)
    if (value != '') {
      await getSortTemplateApi(value, page, slug, search).then(async (response) => {
        setProductList([response.data])
        setTemplateTotal(response.totalCards)
        setLoader(false)
      })
    } else {
      await handleSortOption('recent', pageNumber, selectFillter, searchsub)
    }
  }
  const popup = (id, index) => {
    confirmAlert({
      title: 'Confirm Action',
      message: 'Are you sure you want to delete this card?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCategory(id, index),
        },
        {
          label: 'No',
          onClick: () => { },
        },
      ],
    })
  }
  const autoUpdateFunction = async (id, key, data, isGet) => {
    let formData = new FormData()
    formData.append(key, data)
    console.log(formData)
    const params = {
      templateId: id,
    }
    await editTemplateApi(formData, params).then(async (response) => {
      if (response.success) {
        setLoader(false)
        if (isGet) {
          await handleSortOption(selectSort, pageNumber, selectFillter, searchsub)
        }
      } else {
        setLoader(false)
      }
    })
  }
  var divisor = (templateTotal / 10) >> 0
  var remainder = templateTotal % 10
  useEffect(async () => {
    await getCategoryList('category').then(async (response) => {
      if (response.success) {
        setParentCategory(await multiSelectTemp(response.data.categories))
        setCategoryList(response.data.categories)
      }
    })
    await getProductList('product-type/producttypelist').then(
      async (response) => {
        if (response.status) {
          setProductoptList(response.data)
        }
      },
    )
    await handleSortOption(selectSort, 1, selectFillter, searchsub)

    //await handleSortOption(selectSort, 0, selectFillter)
  }, [])
  const isCheckInital = (array) => {
    return array?.map((items) =>
      items?._id && items?.name
        ? { value: items?._id, label: items?.name }
        : items,
    )
  }
  return (
    <>
      <div
        className="nk-content-fluid "
        style={{ padding: '0px', paddingTop: '5%', paddingBottom: '15px' }}
      >
        <div className="container-fluid" style={{ padding: '10px' }}>
          <div className="nk-content-inner">
            <div className="nk-content-body" style={{ padding: '0px' }}>
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Template List</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{' '}
                        {templateTotal === 1
                          ? templateTotal + ' template'
                          : templateTotal + ' templates'}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <a
                            className="btn btn-white btn-outline-light"
                            onClick={(e) => {
                              setOpen(true)
                            }}
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Template</span>
                          </a>
                        </li>
                        <li>
                          <select
                            className="btn btn-white btn-outline-light"
                            value={selectSort}
                            onChange={(e) => {
                              handleSortOption(e.target.value, 1, selectFillter, searchsub)
                            }}
                          >
                            {/* <option value=''>Sort By</option> */}
                            <option value="recent">Recent</option>
                            <option value="popular">Popular</option>
                          </select>
                        </li>
                        <li>
                          <select
                            className="btn btn-white btn-outline-light"
                            value={searchsub}
                            onChange={(e) => {
                              handleSortOption(selectSort, 1, selectFillter, e.target.value)
                              setSearchsub(e.target.value)
                            }}
                          >
                            {/* <option value=''>Sort By</option> */}
                            <option value="">All</option>
                            <option value={true}>Private</option>
                          </select>
                        </li>
                        <li>
                          <select
                            className="btn btn-white btn-outline-light"
                            value={selectFillter}
                            onChange={(e) => {
                              handleSortOption(selectSort, 1, e.target.value, searchsub)
                            }}
                          >
                            <option value="">All Category</option>
                            {categoryListSelect?.map((data, index) => (
                              <>
                                <option value={data?.slug} key={index}>
                                  {data?.name}
                                </option>
                                {data?.children?.map((subData, subIndex) => (
                                  <option value={subData?.slug} key={subIndex}>
                                    {subData?.name}
                                  </option>
                                ))}
                              </>
                            ))}
                          </select>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={
                          toggleSearchBar
                            ? 'card-search search-wrap active'
                            : 'card-search search-wrap active'
                        }
                        data-search="search"
                      >
                        <div className="card-body">
                          <div className="search-content">
                            <input
                              type="text"
                              className="form-control border-transparent form-focus-none"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  handleSerach(search)
                                }
                              }}
                              placeholder="Search by template/keyword name..."
                              style={{ paddingLeft: '0px' }}
                            />
                            <button
                              onClick={() => handleSerach(search)}
                              className="search-submit btn btn-icon"
                            >
                              <em className="icon ni ni-search"></em>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0" style={{ width: '100%' }}>
                      <div
                        className="nk-tb-list nk-tb-ulist"
                        style={{ width: '100%' }}
                      >
                        <Table>
                          <Thead className="tableHead">
                            <Tr>
                              <Th
                                className="text-center"
                                style={{ fontSize: '13px', lineHeight: '41px' }}
                              >
                                Template Cover/Name/Description
                              </Th>
                              <Th
                                className="text-center"
                                style={{ fontSize: '13px', lineHeight: '41px' }}
                              >
                                Alt Tag/Keyword/Popularity
                              </Th>
                              <Th
                                className="text-center"
                                style={{ fontSize: '13px', lineHeight: '41px' }}
                              >
                                Category/Product/Slug
                              </Th>
                              <Th
                                className="text-center"
                                style={{ fontSize: '13px', lineHeight: '41px' }}
                              >
                                Date
                              </Th>
                              <Th
                                className="text-center"
                                style={{ fontSize: '13px', lineHeight: '41px' }}
                              >
                                Action
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {loader ? (
                              <LoadingOverlay
                                className="loaderDiv1"
                                style={{
                                  zIndex: '999999999',
                                  left: '0px',
                                  top: '0px',
                                  position: 'absolute',
                                }}
                              >
                                <Loader loading={loader} />
                              </LoadingOverlay>
                            ) : null}
                            {productList[0]?.length > 0 ? (
                              productList[0]?.map((data, index) =>
                                data ? (
                                  <Tr
                                    style={{ border: 'solid #e6e6e6 1px' }}
                                    key={index}
                                  >
                                    <Td
                                      className="text-center"
                                      style={{ padding: '15px' }}
                                    >
                                      <div
                                        className=""
                                        style={{
                                          border: 'solid #cecece 1px',
                                          paddingTop: '15px',
                                          paddingBottom: '15px',
                                          borderRadius: '15px',
                                          position: 'relative',
                                          width: '100%',
                                          textAlign: 'center',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <div
                                          className="divChild"
                                          style={{
                                            width: '100%',
                                            margin: '0px',
                                            padding: '15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <img
                                            className="tb-lead"
                                            style={{
                                              width: '55px',
                                              height: '75px',
                                              margin: '5px',
                                              zIndex: '99',
                                              position: 'relative',
                                            }}
                                            alt={
                                              data?.frontAlt
                                                ? data?.frontAlt
                                                : ''
                                            }
                                            title={
                                              data?.frontAlt
                                                ? data?.frontAlt
                                                : ''
                                            }
                                            src={BASE_IMAGE_URL + data.front}
                                          />
                                          <img
                                            className="tb-lead"
                                            style={{
                                              width: '55px',
                                              height: '75px',
                                              margin: '5px',
                                              position: 'relatie',
                                            }}
                                            src={BASE_IMAGE_URL + data?.back}
                                            alt="back"
                                          />
                                        </div>
                                        <div
                                          className="divChild"
                                          style={{
                                            width: '100%',
                                            margin: '0px',
                                            padding: '15px',
                                          }}
                                        >
                                          <FileUploader
                                            className="tb-lead text-center"
                                            style={{
                                              width: '100%',
                                              marginTop: '15px',
                                              height: '100%',
                                            }}
                                            handleChange={(e) => {
                                              setLoader(true)
                                              autoUpdateFunction(
                                                data?._id,
                                                'front',
                                                e,
                                                true,
                                              )
                                            }}
                                            name="frontFile"
                                            types={fileTypesButton}
                                            label="Upload or drop front image here"
                                          />
                                          <FileUploader
                                            className="tb-lead text-center"
                                            style={{
                                              width: '100%',
                                              marginTop: '15px',
                                              height: '100%',
                                            }}
                                            handleChange={(e) => {
                                              setLoader(true)
                                              autoUpdateFunction(
                                                data?._id,
                                                'back',
                                                e,
                                                true,
                                              )
                                            }}
                                            name="backFile"
                                            types={fileTypesButton}
                                            label="Upload or drop back image here"
                                          />
                                        </div>
                                        <div
                                          className="divChild"
                                          style={{
                                            width: '100%',
                                            margin: '0px',
                                            padding: '15px',
                                            borderTop: 'solid #cecece 1px',
                                          }}
                                        >
                                          <textarea
                                            className="tb-lead text-center"
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              border: 'solid #cecece 1px',
                                              padding: '15px 10px',
                                              borderRadius: '15px',
                                            }}
                                            placeholder="Template Name"
                                            type="text"
                                            value={data?.name}
                                            onChange={(e) => {
                                              let updateLsit = productList[0]
                                              var update = [...updateLsit]
                                              update[index] = {
                                                ...update[index],
                                                ['name']: e.target.value,
                                              }
                                              setProductList([update])
                                            }}
                                            onKeyUp={(e) => {
                                              autoUpdateFunction(
                                                data?._id,
                                                'name',
                                                e.target.value,
                                                false,
                                              )
                                            }}
                                          ></textarea>
                                        </div>
                                        <div
                                          className="divChild"
                                          style={{
                                            width: '100%',
                                            margin: '0px',
                                            padding: '15px',
                                            borderTop: 'solid #cecece 1px',
                                          }}
                                        >
                                          <textarea
                                            className="tb-lead text-center"
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              border: 'solid #cecece 1px',
                                              padding: '15px 10px',
                                              borderRadius: '15px',
                                            }}
                                            placeholder="Template Description"
                                            type="text"
                                            value={data?.description}
                                            onChange={(e) => {
                                              let updateLsit = productList[0]
                                              var update = [...updateLsit]
                                              update[index] = {
                                                ...update[index],
                                                ['description']: e.target.value,
                                              }
                                              setProductList([update])
                                            }}
                                            onKeyUp={(e) => {
                                              autoUpdateFunction(
                                                data?._id,
                                                'description',
                                                e.target.value,
                                                false,
                                              )
                                            }}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </Td>
                                    <Td
                                      className="text-center"
                                      style={{ padding: '15px' }}
                                    >
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <textarea
                                          className="tb-lead text-center"
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            border: 'solid #cecece 1px',
                                            padding: '15px 10px',
                                            borderRadius: '15px',
                                          }}
                                          placeholder="Template Front Alt Tag"
                                          type="text"
                                          value={data?.frontAlt}
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['frontAlt']: e.target.value,
                                            }
                                            setProductList([update])
                                          }}
                                          onKeyUp={(e) => {
                                            autoUpdateFunction(
                                              data?._id,
                                              'frontAlt',
                                              e.target.value,
                                              false,
                                            )
                                          }}
                                        ></textarea>
                                      </div>
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <textarea
                                          className="tb-lead text-center"
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            border: 'solid #cecece 1px',
                                            padding: '15px 10px',
                                            borderRadius: '15px',
                                          }}
                                          placeholder="write keyword..."
                                          type="text"
                                          value={data?.keyword}
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['keyword']: e.target.value,
                                            }
                                            setProductList([update])
                                          }}
                                          onKeyUp={(e) => {
                                            autoUpdateFunction(
                                              data?._id,
                                              'keyword',
                                              e.target.value,
                                              false,
                                            )
                                          }}
                                        ></textarea>
                                      </div>
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <input
                                          className="tb-lead text-center uiGP"
                                          placeholder="Set popularity[0-100]"
                                          min="0"
                                          max="100"
                                          type="number"
                                          style={{ width: '100%' }}
                                          value={data?.setPopularity}
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['setPopularity']: e.target.value,
                                            }
                                            setProductList([update])
                                            autoUpdateFunction(
                                              data?._id,
                                              'setPopularity',
                                              e.target.value,
                                              false,
                                            )
                                          }}
                                          onKeyUp={(e) => {
                                            autoUpdateFunction(
                                              data?._id,
                                              'setPopularity',
                                              e.target.value,
                                              false,
                                            )
                                          }}
                                        />
                                      </div>
                                    </Td>
                                    <Td
                                      className="text-center"
                                      style={{ padding: '15px' }}
                                    >
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <MultiSelect3
                                          className="tb-lead"
                                          defaultValue={
                                            isCheckInital(data?.category)
                                              ?.length
                                              ? isCheckInital(data?.category)
                                              : []
                                          }
                                          clearable={false}
                                          customValue={false}
                                          jsonValue={false}
                                          placeholder="Select Categories"
                                          style={{ width: '100%' }}
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['category']: e?.split(','),
                                            }
                                            setProductList([update])
                                            autoUpdateFunction(
                                              data?._id,
                                              'category',
                                              JSON.stringify(
                                                e.split(','),
                                                false,
                                              ),
                                            )
                                          }}
                                          options={
                                            parentCategory?.length
                                              ? parentCategory
                                              : []
                                          }
                                        />
                                      </div>
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <MultiSelect3
                                          className="tb-lead"
                                          defaultValue={
                                            data?.productType
                                              ? data?.productType
                                              : ''
                                          }
                                          clearable={false}
                                          placeholder="Select product type"
                                          singleSelect={true}
                                          customValue={false}
                                          jsonValue={false}
                                          style={{ width: '100%' }}
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['productType']: e,
                                            }
                                            setProductList([update])
                                            autoUpdateFunction(
                                              data?._id,
                                              'productType',
                                              e,
                                              false,
                                            )
                                          }}
                                          options={
                                            productoptList?.length
                                              ? productoptList?.map(
                                                (dataPro) => ({
                                                  value: dataPro?._id,
                                                  label: dataPro?.name,
                                                }),
                                              )
                                              : []
                                          }
                                        />
                                      </div>
                                      <div
                                        className="user-info tb-lead"
                                        style={{ margin: '5px' }}
                                      >
                                        <select
                                          className="custom-select uiGP"
                                          value={data?.slug}
                                          name="slug"
                                          onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['slug']: e.target.value,
                                            }
                                            setProductList([update])
                                            // if (e.target.value) {
                                            autoUpdateFunction(
                                              data?._id,
                                              'slug',
                                              e.target.value,
                                              false,
                                            )
                                            //}
                                          }}
                                        >
                                          <option value="">Select Slug</option>
                                          {categoryListSelect?.map(
                                            (data, index) => (
                                              <>
                                                <option
                                                  value={data?.slug}
                                                  key={index}
                                                >
                                                  {data?.slug}
                                                </option>
                                                {data?.children?.map(
                                                  (subData, subIndex) => (
                                                    <option
                                                      value={
                                                        data?.slug +
                                                        '/' +
                                                        subData?.slug
                                                      }
                                                      key={subIndex}
                                                    >
                                                      {data?.slug +
                                                        '/' +
                                                        subData?.slug}
                                                    </option>
                                                  ),
                                                )}
                                              </>
                                            ),
                                          )}
                                        </select>
                                      </div>
                                      <div>
                                        <div class="form-check">

                                          <input type='checkbox' name="private" className='form-check-input' checked={data?.private} onChange={(e) => {
                                            let updateLsit = productList[0]
                                            var update = [...updateLsit]
                                            update[index] = {
                                              ...update[index],
                                              ['private']: e.target.checked,
                                            }
                                            setProductList([update])
                                            autoUpdateFunction(
                                              data?._id,
                                              'private',
                                              e.target.checked,
                                              false,
                                            )
                                          }} />
                                          <label class="form-check-label" for="private">
                                            Private
                                          </label>
                                        </div>
                                      </div>
                                    </Td>
                                    <Td className="text-center">
                                      {format(
                                        new Date(data?.createdAt),
                                        'Do MMM YYYY, HH:mm A',
                                      )}
                                    </Td>
                                    <Td className="text-center">
                                      <ul className="nk-tb-actions gx-1 text-center">
                                        <li
                                          className="text-center"
                                          style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div
                                            className="drodown text-center"
                                            style={{
                                              width: '45px',
                                              height: '45px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            <a
                                              href="#"
                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                              data-toggle="dropdown"
                                            >
                                              <em className="icon ni ni-more-h"></em>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                              <ul className="link-list-opt no-bdr">
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      editCategory(data)
                                                    }
                                                  >
                                                    <em className="icon ni ni-edit"></em>
                                                    <span>Edit</span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      popup(data._id, index)
                                                    }
                                                  >
                                                    <em className="icon ni ni-trash"></em>
                                                    <span>Delete</span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </Td>
                                  </Tr>
                                ) : null,
                              )
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  position: 'absolute',
                                  left: '0px',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <h1
                                  style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    lineHeight: '61px',
                                    color: '#eb5757',
                                    fontWeight: '600',
                                  }}
                                >
                                  *Not Found
                                </h1>
                              </div>
                            )}
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                    {paginationOff ? null : (
                      <div className="card-inner">
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={10}
                          onPageChange={async (e) => {
                            if (selectSort != '') {
                              await handleSortOption(
                                selectSort,
                                e.selected + 1,
                                selectFillter,
                                searchsub
                              )
                            } else {
                              await handleSortOption(
                                selectSort,
                                e.selected + 1,
                                selectFillter,
                                searchsub
                              )
                            }
                          }}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={(e) => onCloseModal()}
        closeOnOverlayClick={false}
      >
        <div className="container-fluid" style={{ width: '100%' }}>
          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <h3 style={{ textAlign: 'left' }}>
                {editData ? 'Edit Template' : 'Add Template'}
              </h3>
            </div>
            <div className="col-md-12 col-12" style={{ marginTop: '15px' }}>
              <div className="row justify-content-center">
                <div className="col-lg-12 col-12">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template Name</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={name}
                          name="templateName"
                          placeholder="Enter Name"
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template Description</div>
                        <textarea
                          type="text"
                          className="data-value ip-field"
                          value={templateDescription}
                          name="templateName"
                          placeholder="Enter Description"
                          onChange={(e) => {
                            setTemplatedescription(e.target.value)
                          }}
                          required
                        ></textarea>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template Keyword</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={keyWord}
                          name="keyword"
                          placeholder="Enter Keyword"
                          onChange={(e) => {
                            setKeyWord(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Category Type</div>
                        <MultiSelect
                          className="multiOptionSelect"
                          name="category"
                          value={category}
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            setCategory(e)
                            if (e.length) {
                              let fetchProductDefault = productoptList.filter(
                                (n) => n.price.toString() === '4.99',
                              )
                              setProductType(
                                fetchProductDefault[0]?._id
                                  ? fetchProductDefault[0]?._id
                                  : '',
                              )
                              setPopularity('6')
                              setName(e[0]?.label + ' Card')
                              setFrontAlt(
                                (e[0]?.label + ' Group Card').toLowerCase(),
                              )
                            } else {
                              setProductType('')
                              setPopularity('')
                              setName('')
                              setFrontAlt('')
                            }
                          }}
                          options={parentCategory?.length ? parentCategory : []}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template Slug</div>
                        <select
                          className="custom-select"
                          value={slug}
                          name="slug"
                          onChange={(e) => setSlug(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          {categoryListSelect?.map((data, index) => (
                            <>
                              <option value={data?.slug} key={index}>
                                {data?.slug}
                              </option>
                              {data?.children?.map((subData, subIndex) => (
                                <option
                                  value={data?.slug + '/' + subData?.slug}
                                  key={subIndex}
                                >
                                  {subData?.slug}
                                </option>
                              ))}
                            </>
                          ))}
                          {/* {productoptList?.map((val, index) => {
                                                        return (
                                                            <option value={val._id} key={index}>{val.name}</option>
                                                        )
                                                    })} */}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Product Type</div>
                        <select
                          className="custom-select"
                          value={productType}
                          name="product"
                          onChange={(e) => setProductType(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          {productoptList?.map((val, index) => {
                            return (
                              <option value={val._id} key={index}>
                                {val.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Popularity</div>
                        <select
                          className="custom-select"
                          value={popularity}
                          name="popularity"
                          onChange={(e) => setPopularity(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          {popularityArray.map((_, i) => {
                            return <option value={i}>{i}</option>
                          })}
                          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, index) => {
                            return (
                              <option value={val} key={index}>
                                {val}
                              </option>
                            )
                          })} */}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Front Side</div>
                        {editData ? (
                          <div className="d-flex align-items-center">
                            <div className="data-value">
                              <img
                                src={BASE_IMAGE_URL + prevFront}
                                alt="img"
                                className="img-dmns"
                              />
                            </div>
                            <input
                              className="ml-3"
                              type="file"
                              id="front"
                              ref={useRefFront}
                              name="front"
                              onChange={(e) => setFront(e.target.files[0])}
                            />
                          </div>
                        ) : (
                          <div
                            {...getRootProps()}
                            style={{
                              border: 'solid #c6c6c6 1px',
                              borderStyle: 'dashed',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '15px',
                              flexDirection: 'column',
                            }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p
                                style={{
                                  textAlign: 'center',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                Drop the files here ...
                              </p>
                            ) : (
                              <p
                                style={{
                                  textAlign: 'center',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                Drag 'n' drop some images here, or click to
                                select images
                              </p>
                            )}
                            {mockupImages?.length ? (
                              <div
                                id="addimages"
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {mockupImages?.map((items, index) => (
                                  <img
                                    src={encodeImageFileAsURL(items)}
                                    className="img-fluid"
                                    key={index}
                                    style={{
                                      width: '100px',
                                      height: '70px',
                                      margin: '5px',
                                    }}
                                  />
                                ))}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template Image Alt</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={frontAlt}
                          name="frontAlt"
                          placeholder="Template Alt"
                          onChange={(e) => {
                            setFrontAlt(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Back Side</div>
                        {editData ? (
                          <div className="d-flex align-items-center">
                            <div className="data-value">
                              <img
                                src={BASE_IMAGE_URL + prevBack}
                                alt="img"
                                className="img-dmns"
                              />
                            </div>
                            <input
                              className="ml-3"
                              type="file"
                              id="back"
                              ref={useRefBack}
                              name="back"
                              onChange={(e) => setBack(e.target.files[0])}
                            />
                          </div>
                        ) : (
                          <input
                            type="file"
                            className="data-value ip-field"
                            placeholder="Choose..."
                            name="back"
                            ref={useRefBack}
                            onChange={(e) => {
                              setBack(e.target.files[0])
                            }}
                          />
                        )}
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Private</div>
                        <input
                          type="checkbox"
                          className="data-value ip-field"
                          name="isPrivate"
                          placeholder="Private"
                          onChange={(e) => {
                            setIsPrivate(e.target.checked)
                          }}
                          checked={isPrivate}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="my-3 text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-md btn-primary"
                        onClick={() => {
                          editData
                            ? handleEditProduct(editId)
                            : handleAddProdcut()
                        }}
                      >
                        {editData ? 'Edit Template' : 'Add Template'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default TemplateList
